import React from 'react'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../features/theme/themeSlice'
import './singleArt.css'
import { useGetPostByIdQuery } from '../../app/api/postApi'
import { useParams } from 'react-router-dom'
import DOMPurify from 'dompurify'
import hope from '../../assets/nudoBorromeo.png'

const Articles = () => {
  const theme = useSelector(selectTheme)
  let {id} = useParams()
  const {data } = useGetPostByIdQuery(id)

  let articleData; 

  if(data){
    articleData = <>         
      {data.capitulos.map(cap => (
        <>
          <div className= {`singleArt ${theme} `}>
            <div className="capImage">
            {cap.image ? <img src={cap.image} alt={cap.sub_title3} /> : <img src={hope} alt="Obeth Torres" width='300px' />}
              <div className="ideasRelevantes">
                <p className='cita'> {cap.cita1} </p>
                <p className="cita"> {cap.cita2} </p>
              </div>
              <div className="referencias">
                <p>{cap.referencias1} </p> 
              </div> 
            </div>            
            <div className="columText">
              <h2>{cap.cap_title}</h2>  
              <div 
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cap.cap_body) }}
              ></div>
            </div>                        
          </div>          

          <div className= {`singleArt ${theme} `}>
            <div className="capImage">
            {cap.image1 ? <img src={cap.image1} alt={cap.sub_title3} /> : <img src={hope} alt="Obeth Torres" width='300px' />}
              <div className="ideasRelevantes">
                <p  className='cita'> {cap.cita3} </p>
                <p className='cita'> {cap.cita4} </p>
              </div>
              <div className="referencias">
                <p>{cap.referencias2} </p> 
              </div>
            </div>            
            <div className="columText">
              <h2>{cap.sub_title1}</h2>            
              <div 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cap.sub_body1)}}
              ></div>           
            </div>             
          </div>

          <div className= {`singleArt ${theme} `}>
            <div className="capImage">
            {cap.image2 ? <img src={cap.image2} alt={cap.sub_title3} /> : <img src={hope} alt="Obeth Torres" width='300px' />}
              <div className="ideasRelevantes">
                <p  className='cita'> {cap.cita5} </p>
                <p className='cita'> {cap.cita6} </p>
              </div>
              <div className="referencias">
                <p>{cap.referencias3} </p> 
              </div>
            </div>            
            <div className="columText">
              <h2>{cap.sub_title2}</h2>            
              <div 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cap.sub_body2)}}
              ></div>           
            </div>             
          </div>

          <div className= {`singleArt ${theme} `}>
            <div className="capImage">
              {cap.image3 ? <img src={cap.image3} alt={cap.sub_title3} /> : <img src={hope} alt="Obeth Torres" width='300px' />}
              
              <div className="ideasRelevantes">
                <p  className='cita'> {cap.cita7} </p>
                <p className='cita'> {cap.cita8} </p>
              </div>
              <div className="referencias">
                <p>{cap.referencias4} </p> 
              </div>
            </div>            
            <div className="columText">
              <h2>{cap.sub_title3}</h2>            
              <div 
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cap.sub_body3)}}
              ></div>           
            </div>             
          </div>
        </>      
      ))}
      
    </>
  }  
  return (
    <div className= {`article ${theme} `}>
    {articleData} 
    </div>
  )
}

export default Articles