import React from 'react'
import { useSelector } from 'react-redux'
import { selectTheme } from '../features/theme/themeSlice'
import './about.css'
import hopeBlue from '../assets/ob.png'


const About = () => {
  const theme = useSelector(selectTheme)

  return (
    <div className={`aboutPage a${theme}`} >
      <div className="aboutme">
        
        <img src={hopeBlue} alt="Obeth Torres" width='300px' />
        
        <div className="cv">
          <div className={`formacion f${theme}`}>
            <p>Psicología Clinica:</p>
            <h4>UNIVERSIDAD DEL VALLE DE MEXICO</h4>
          </div>
          <div className={`formacion f${theme}`}>
            <p>Psicoanálisis de Orientación lacaniana:</p>
            <h4>NUCEP</h4>
          </div>
          <div className={`formacion f${theme}`}>
            <p>Filosofía:</p>
            <h4>UNED</h4>
          </div>
          <div className={`formacion f${theme}`}>
            <p>Desarrollo Web:</p>
            <h4>IN THE WEB</h4>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default About