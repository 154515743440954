import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import { postApi } from './api/postApi';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import themeReducer from '../features/theme/themeSlice';

export const store = configureStore({
  reducer: {
    [postApi.reducerPath]: postApi.reducer,
    counter: counterReducer,
    theme: themeReducer,    
  },

  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(postApi.middleware),
});

setupListeners(store.dispatch)
