import React from 'react'
import './articlesPage.css'
import { useGetAllPostsQuery } from '../../app/api/postApi'
import ArticleExcerpt from './ArticleExcerpt'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../features/theme/themeSlice'

const ArticlesPage = () => {
    const theme = useSelector(selectTheme)
    const { data: posts, isSuccess, isLoading, isError, error } = useGetAllPostsQuery('')
       
    let content;
    if(isLoading){
        content = <p>Loading...</p>
    } else if(isSuccess){
        content = posts.map(post => {     
            return (
                <ArticleExcerpt key={post.id} post={post}/>                
            )        
        })
    } else if(isError){
        content = <p> {error} </p>
    }


  return (
    <div className= {`articlePage ${theme}` } >
      <h1>capítulos  1-4</h1>        
      <p className='capTitle'>Fundamentación Teorica de la Noción de Sujeto</p>
      <div className="line1"></div>
        
        {content}

    </div>
  )
}

export default ArticlesPage