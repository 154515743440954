import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const postApi = createApi({
    reducerPath: 'postApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.psicologia-y-psicoanalisis.org'}),

    endpoints: (builder) => ({
        getAllPosts: builder.query({
            query: () => '/api/',
        }),
        getPostById: builder.query({
            query:(id) => `/api/${id}`,
        }),
    }),
});

export const { useGetAllPostsQuery, useGetPostByIdQuery } = postApi