import React, { useState } from 'react'
import { IoIosHome } from 'react-icons/io'
import { MdDarkMode } from 'react-icons/md'
import { BsFillSunFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import './navigation.css'
import { useSelector, useDispatch } from 'react-redux'
import { themeDark, selectTheme, themeLight } from '../features/theme/themeSlice'

const Navigation = () => {
  const themeRedux = useSelector(selectTheme)
  const dispatch = useDispatch()

  const [theme, setTheme] = useState(themeRedux)

  const toggleTheme = () => {
    if(theme === 'light'){
      setTheme('dark')
      dispatch(themeDark())
    } else {
      setTheme('light')
      dispatch(themeLight())
    }
  }

  return (
    <div className={`navigationBar nav${theme} `}>
        <Link to='about'>  <div className="logo"> <p>OB</p>  </div></Link>            
        <Link to='introducción' className='links' >Introducción</Link>   
        <Link to='articles' className='links'>Capítulos</Link>
        <button className={`dnl${theme}` } onClick={toggleTheme} >
          {theme === 'light' ? <MdDarkMode/> : <BsFillSunFill/> }        
        </button>
        <Link to='/' className= 'homeIcon ' > <IoIosHome/> </Link>
    </div>
  )
}

export default Navigation