import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    theme: 'dark',
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        themeDark: (state) => {
            state.theme = 'dark' ;
        },
        themeLight: (state) => {
            state.theme = 'light'
        }
    },
})

export const { themeDark, themeLight } = themeSlice.actions;
export const selectTheme = (state) => state.theme.theme;
export default themeSlice.reducer;