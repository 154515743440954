import React from 'react'
import './intro.css'
import { useSelector } from 'react-redux'
import { selectTheme } from '../features/theme/themeSlice'

const Intro = () => {
    const theme = useSelector(selectTheme)
  return (
    <div className={`intro ${theme}`} >
          <h1>Intriducción a La Noción de Sujeto</h1>        
        <div className="line1"></div>
        <div className='introBody'>
          <p>
           <strong>La propuesta   de investigación</strong> es fundamentalmente el análisis de la noción de Sujeto, 
            lo que pretendo defender es que la práctica clínica del campo de la Psicología depende 
            fundamentalmente de la posición ontológica y epistemológica respecto a la noción de sujeto.
          </p> 
          <p>
            Es decir, en función de lo que se crea que el hombre es, se habilitan un campo de 
            posibilidades acerca de como pensar la experiencia y particularmente la experiencia de sufrimiento
            A su vez, las personas sufren, en función de cómo ellas y su entorno piensan 
            ciertos conceptos fundamentales, es decir; en función de cómo se piense el fracaso, 
            en función de como se piense la dignidad... La experiencia de fracaso puede acontecer,
             algo como una vivienda digna, un trabajo digno, un trato digno tiene sentido
              en función de la noción de dignidad.
          </p>
          <p>Con lo cual, lo que se va a proponer a lo largo de este recorrido es que la práctica
             clínica de una <strong> Psicología auténtica </strong> (que es así como lo propone Husserl, psicología auténtica) 
             debe realizarse necesariamente haciendo de la Filosofía su herramienta principal de trabajo.
           </p>

          <p> Pues es en el campo de la Filosofía donde se cuenta con los elementos epistémicos con lo 
          cuales pensar las interrogantes que el caso clínico plantea. No necesariamente lo que
           el paciente pregunta, si no aquello que se pregunta en el propio caso, los conceptos 
           que son cuestionados. Es en el campo de la F. donde son pensados.
           </p>

          <p>Ahora bien, <strong>la pregunta por el sujeto </strong>  es de tal  importancia que su estudio convoca
             prácticamente a todo el abanico de posibilidades científicas, cada una de las
              diciplinas científicas presenta trabajos relevantes respecto a la noción de sujeto,
               de hombre, persona, individuo, etc. por lo que es loable defender que investigación
                antropológica es la investigación principal de toda la comunidad científica y que la 
                antropología filosófica es la filosofía primera. es en esa misma línea argumentativa 
                en la que se quiere situar esta investigación, de tal forma que podamos articular 
                la noción de sujeto con la cual desarrollar una psicología auténtica.
              Para ello este proyecto inicia con Hegel, después Husserl, Heidegger y finalmente Lacan.</p>
          <p>
              ahora bien, conviene acompañar esta lectura con tres problemas clave.
            1º del campo de la Economía; <strong> el problema de la libertad </strong>  
             2º del campo de la Sociología;  el debate respecto al <strong>origen fundamentalmente social de todo saber científico </strong> 
             3º del campo de la Estética; su trabajo respecto a <strong> la experiencia.</strong>  
          </p> 
        </div>
        <div className="line2"></div>
    </div>
  )
}

export default Intro