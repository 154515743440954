import React from 'react'
import { Link } from 'react-router-dom'
import './articlesPage.css'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../features/theme/themeSlice'
import DOMPurify from 'dompurify'

const ArticleExcerpt = ({ post }) => {
  const theme = useSelector(selectTheme)
    
  return (
    <div className= {`articleExcerpt ${theme}`}>
      <img src={post.image} alt="deseo" height={'400px'}/> 
      <div className='articleTitle'>
        <h2> {post.title} </h2>                
        <div className='abstract'
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.abstract) }}
        ></div>
        
        <Link to= {`../article/${post.id}`} >Continuar lellendo...</Link>
      </div>    
          
    </div>
  )
}

export default ArticleExcerpt