import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import About from './pages/About';
import Articles from './pages/articles/Articles';
import ArticlesPage from './pages/articles/ArticlesPage';
import Home from './pages/Home';
import Intro from './pages/Intro';

function App() {
  return (
    <div className="App">
      <Navigation/>
      <Routes>
        <Route index element={<Home/>}/>
        <Route path='about' element={<About/>} />
        <Route path='introducción' element={<Intro/>}/>
        <Route path='articles' element={<ArticlesPage/>}/>
        <Route path='article/:id' element={<Articles />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
