import React from 'react'
import './toros.css'

const Toros = () => {
  return (
    <div className='toros'>
        {/* <div className="toro1"></div>
        <div className="toro2"></div> */}

        <svg version="1.1" id="Capa_1"   x="0px" y="0px"
            width="500px" height="500px" viewBox="0 0 500 500" enableBackground="new 0 0 500 500">
          <circle fill="none" stroke="#DD2F58" 
            strokeWidth="15" strokeMiterlimit="10" cx="172" cy="147" r="126"/>
          <g className='toro2'>
            <circle fill="none" stroke="#FFBD1F" stroke-width="14" 
              strokeMiterlimit="10" strokeDasharray="8.0173,2.0043" cx="333" cy="147" r="126"/>
          </g>
          <circle fill="none" stroke="#DD2F58" strokeWidth="15" strokeMiterlimit="10" strokeDasharray="100,50" cx="172" cy="147" r="126"/>
        </svg>
    </div>
  )
}

export default Toros