import React from 'react'
import './footer.css'
import { useSelector } from 'react-redux'
import { selectTheme } from '../features/theme/themeSlice'

const Footer = () => {
  const theme = useSelector(selectTheme)
  return (
    <div className= {`footer${theme}`}>
        <p>Projecto de investigación 2022</p>
    </div>
  )
}

export default Footer