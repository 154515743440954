import React from 'react'
import { useSelector } from 'react-redux'
import Toros from '../animations/Toros'
import { selectTheme } from '../features/theme/themeSlice'
import './home.css'

const Home = () => {
  const theme = useSelector(selectTheme)

  return (
    <div className={`homePage ${theme}`} >
       <div className={`portada p${theme}`}>
        <h1 className={`title title${theme}`}>La Noción de Sujeto en el Campo de la Psicología Auténtica</h1> 
                   
        <Toros/>
        <p className={`autor `}>OB: Obeth Torres Moreno</p>

       </div>
      
      
       
    </div>
  )
}

export default Home